body {
  background-color: black;
}

.fadein {
  -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  animation: 1s ease 0s normal forwards 1 fadein;
}
.fadeout {
  -webkit-animation: 1s ease 0s normal reverse 1 fadein;
  animation: 1s ease 0s normal reverse 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form {
  box-shadow: 0px -4px 45px #00000060;
  border-radius: 24px;
  padding: 48px;
  display: inline-flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.white {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #000000;
  box-shadow: 0px -4px 45px #ffffff90;
}

.dark {
  background: #000000 0% 0% no-repeat padding-box;
  color: white;
}
.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: black;
  color: white;
}
.app_background_casa {
  background: url("/public/3.png") 0% 0% no-repeat padding-box;
}
.app_background_fortuna {
  background: url("/public/fortuna-bg.png") 0% 0% no-repeat padding-box;
}

.modal {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form > .label {
  font-family: Anton;
  font-size: 46px;
}
.form > .comment {
  font-family: "Open Sans";
  font-size: 17px;
}
.form .input {
  display: inline-flex;
  background: #e2e2e4;
  border-radius: 8px;
  flex-grow: 1;
  min-height: 65px;
  max-width: 100%;
  font-size: 30px;
  font-family: "Anton";
  letter-spacing: 4px;
  line-height: 37px;
  padding: 12px 24px;
  box-sizing: border-box;
}
.input.error {
  border: solid 2px #ed1c24;
}
.comment.error {
  color: #ed1c24;
}

.keyboard {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.num-keyboard {
  width: 390px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.num-keyboard > .key {
  margin-bottom: 16px;
  margin-right: 16px;
  width: 103px;
  height: 63px;
}

.min-w-64 {
  min-width: 64px;
}

.keyboard-digits {
  display: flex;
  flex-direction: row;
  margin: 4px 0;
  justify-content: center;
}
.keyboard-letters {
  display: flex;
  flex-direction: row;
  margin: 4px 0;
  justify-content: center;
}

.key {
  display: inline-flex;
  min-width: 64px;
  height: 64px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 0px rgba(166, 166, 166, 1);
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #000;
  margin: 0 2px;
  justify-content: center;
  align-items: center;
  user-select: none;
}
* {
  user-select: none;
}

.key-active {
  background: #cccccc;
  box-shadow: 0px 0px 0px rgb(80, 80, 80);
  transform: translateY(4px);
  transition: 0.1s;
}

.key-dark {
  background: #646464;
  box-shadow: 0px 4px 0px rgb(80, 80, 80);
  color: white;
  fill: white;
}
.key-dark-active {
  box-shadow: 0px 0px 0px rgb(80, 80, 80);
  transform: translateY(4px);
  transition: 0.1s;
}

.button {
  display: inline-flex;
  align-items: center;
  padding: 12px;
  justify-content: center;
  background: #fff200;
  box-shadow: 0px 4px 0px rgb(201, 186, 0);
  border-radius: 5px;
  font-size: 27px;
  color: black;
}

.button.disabled {
  background: #232323;
  box-shadow: 0px 0px 0px rgb(80, 80, 80);
}
