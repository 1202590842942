body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Anton";
  src: local("Anton-Regular"), url(./fonts/Anton-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./fonts/OpenSans-VariableFont_wdth.ttf) format("truetype");
}

* {
  /* user-select: "none"; */
  -webkit-user-drag: none;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  max-width: unset;
}
